import React from "react";
import classNames from "classnames";
import RibbonsTwo from "@svg/RibbonsTwo";
import { InfoCard } from "@molecules";
import { Container, Text } from "@atoms";

const ContainedGrid = ({
  bgColor,
  copy,
  items,
  overlap,
  className: _className,
}) => {
  return (
    <section
      className={classNames(
        "relative z-30 pb-16",
        {
          "bg-midnight": bgColor === "midnight",
        },
        _className
      )}
    >
      {overlap && (
        <div
          className={classNames("absolute inset-x-0 h-64 md:h-48 lg:h-1/5", {
            "bg-white": bgColor === "midnight",
          })}
        />
      )}
      <Container variant="lg" className="relative z-20">
        <div className="bg-white p-8 shadow-lg md:p-12">
          <div className="flex flex-col items-center">
            <div className="mx-auto max-w-3xl">
              <Text
                richText
                className="text-center prose-p:text-lg lg:prose-p:text-xl"
              >
                {copy}
              </Text>
            </div>
            <div className="mt-8 flex flex-wrap justify-center gap-8 md:mt-12 lg:gap-12">
              {items?.map(item => {
                return (
                  <InfoCard
                    {...item}
                    key={item.uid}
                    className="flex-grow md:flex-[0_1_calc(50%-1rem)] lg:flex-[0_1_calc(33.33%-2rem)]"
                  />
                );
              })}
            </div>
          </div>
        </div>
      </Container>
      <RibbonsTwo
        color="mint"
        className="absolute inset-x-0 bottom-6 z-10 md:-bottom-6"
      />
    </section>
  );
};

ContainedGrid.defaultProps = {
  overlap: false,
};

export default ContainedGrid;
